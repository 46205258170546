import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import LearnMoreButton from "./learnMoreButton"
import { device } from "../styles/device"
import { useIntl } from "gatsby-plugin-react-intl"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const MaskImageAnimation = ({ src, animationKey }) => {
    const controls = useAnimation()
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        inView ? controls.start("animate") : controls.start("initial")
    }, [controls, inView])

    const transition = { duration: 1.4, ease: [0.6, -0.05, 0.01, 0.9] }
    const mask = {
        initial: {
            width: "100%",
        },
        animate: {
            width: 0,
        },
    }
    const animateImage = {
        initial: {
            scale: 1.2,
        },
        animate: {
            scale: 1,
        },
    }

    return (
        <>
            <motion.div
                ref={ref}
                initial="initial"
                animate={controls}
                transition={transition}
                variants={animateImage}
                style={{
                    width: "100%",
                    height: "100%",
                }}
                key={`mask-container-${animationKey}`}
            >
                <GatsbyImage
                    image={getImage(src)}
                    key={`introduction-image-${animationKey}`}
                    alt={`introduction image - ${animationKey}`}
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                />
            </motion.div>
            <motion.div
                ref={ref}
                key={`mask-${animationKey}`}
                css={maskImage}
                initial="initial"
                animate={controls}
                variants={mask}
                transition={transition}
            ></motion.div>
        </>
    )
}

const FrontPageSecondView = () => {
    const intl = useIntl()

    const controls = useAnimation()
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        inView ? controls.start("animate") : controls.start("initial")
    }, [controls, inView])

    const transition = { duration: 1.4, ease: [0.6, -0.05, 0.01, 0.9] }
    const textAnime = {
        initial: {
            y: 10,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
        },
    }

    const data = useStaticQuery(graphql`
        query {
            allContentfulFrontPage {
                nodes {
                    secondViewHeadline
                    secondViewDescription {
                        secondViewDescription
                    }
                    introductionImages {
                        gatsbyImageData
                    }
                }
            }
        }
    `)
    const content = intl.locale === "ja" ? data.allContentfulFrontPage.nodes[0] : data.allContentfulFrontPage.nodes[1]

    return (
        <div css={wrapper}>
            <div css={innerWrap}>
                <div css={imageContainer}>
                    <MaskImageAnimation src={content.introductionImages[0]} animationKey="0" />
                </div>
                <div css={rightContent}>
                    <motion.div
                        css={textBox}
                        ref={ref}
                        initial="initial"
                        animate={controls}
                        transition={transition}
                        variants={textAnime}
                    >
                        <h6>{content.secondViewHeadline}</h6>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: content.secondViewDescription.secondViewDescription.replace(/\n/g, "<br>"),
                            }}
                        />
                        <LearnMoreButton link="/concept" />
                    </motion.div>
                    <div css={imageContainer1}>
                        <MaskImageAnimation src={content.introductionImages[1]} animationKey="1" />
                    </div>
                </div>
            </div>
            <div css={imageContainer2}>
                <MaskImageAnimation src={content.introductionImages[2]} animationKey="2" />
            </div>
        </div>
    )
}
export default FrontPageSecondView

const wrapper = css`
    width: min(100%, 2000px);
    margin: 0 auto 30vmin;
    @media ${device.sp} {
        margin-bottom: 15vmax;
    }
`
const innerWrap = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media ${device.sp} {
        display: block;
    }
`
const imageContainer = css`
    width: 35%;
    height: 80vh;
    margin-left: 5%;
    position: relative;
    overflow: hidden;
    @media ${device.tb} {
        height: 50vmin;
    }
    @media ${device.sp} {
        width: 100%;
        height: 40vmax;
        margin: 0;
    }
`
const imageContainer1 = css`
    /* width: 46vh;
    height: 46vh; */
    width: 32vw;
    height: 32vw;
    margin-top: -3vh;
    /* margin-left: calc(100% - 46vh); */
    margin-left: calc(100% - 35vw);
    position: relative;
    overflow: hidden;
    @media ${device.tb} {
        width: 30vmax;
        height: 30vmax;
        margin: 0 0 0 auto;
    }
    @media ${device.sp} {
        width: 27vmax;
        height: 27vmax;
        margin-left: calc(100% - 29vmax);
    }
`
const imageContainer2 = css`
    width: 55%;
    max-height: 57vh;
    margin-top: 10vh;
    position: relative;
    overflow: hidden;
    @media ${device.sp} {
        width: 84%;
        height: 26vmax;
        /* margin: 10vmin 0 0 4%; */
        margin: 10vmin 0 0 0;
    }
`
const maskImage = css`
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
`
const rightContent = css`
    width: 60%;
    @media ${device.sp} {
        width: 100%;
    }
`
const textBox = css`
    width: 55%;
    margin: 20vmin auto 20vmin 15%;

    @media ${device.tb} {
        width: 80%;
        margin: 10vmin auto 20vmin 10%;
    }
    @media ${device.sp} {
        margin: 10vmin auto 30vmin;
    }

    h6 {
        margin-bottom: 3rem;
    }
    p {
        margin-bottom: 7rem;
    }
`
