import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { device } from "../styles/device"
import OrderNowButton from "./orderNowButton"
import VideoSp from "../images/A_0087_3.mp4"
import VideoSpWEBM from "../images/A_0087_3.webm"
import { useIntl } from "gatsby-plugin-react-intl"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const FrontPageShopSection = () => {
    const intl = useIntl()

    const controls = useAnimation()
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        inView ? controls.start("animate") : controls.start("initial")
    }, [controls, inView])

    const transition = { duration: 1.4, ease: [0.6, -0.05, 0.01, 0.9] }
    const textAnime = {
        initial: {
            y: 10,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
        },
    }

    let options = {
        root: null,
        rootMargin: "600px",
        threshold: 0,
    }
    const videoRef = useRef(null)
    useEffect(() => {
        const cb = (entries) => {
            if (entries[0].isIntersecting) {
                for (let source in entries[0].target.children) {
                    let videoSource = entries[0].target.children[source]
                    if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                        videoSource.src = videoSource.dataset.src
                    }
                }

                entries[0].target.load()
                observer.unobserve(videoRef.current)
            }
        }

        const observer = new IntersectionObserver(cb, options)
        observer.observe(videoRef.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const data = useStaticQuery(graphql`
        query {
            allContentfulFrontPage {
                nodes {
                    onlineShopHeadline
                    onlineShopDescription {
                        onlineShopDescription
                    }
                    onlineShopImages {
                        gatsbyImageData
                    }
                }
            }
        }
    `)
    const content = intl.locale === "ja" ? data.allContentfulFrontPage.nodes[0] : data.allContentfulFrontPage.nodes[1]

    return (
        <div css={wrapper}>
            <div css={leftContent}>
                <motion.div
                    css={textBox}
                    ref={ref}
                    initial="initial"
                    animate={controls}
                    transition={transition}
                    variants={textAnime}
                >
                    <h2>{content.onlineShopHeadline}</h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: content.onlineShopDescription.onlineShopDescription.replace(/\n/g, "<br>"),
                        }}
                    />
                    <div css={normalOrderButton}>
                        <OrderNowButton />
                    </div>
                </motion.div>
            </div>
            <div css={rightContent}>
                <GatsbyImage
                    image={getImage(content.onlineShopImages[0])}
                    key="introduction-image-0"
                    alt="online shop image - 0"
                    css={onlineShopImage}
                />
                <GatsbyImage
                    image={getImage(content.onlineShopImages[1])}
                    key="introduction-image-1"
                    alt="online shop image - 1"
                    css={[onlineShopImage, onlineShopImage2]}
                />
                <video muted autoPlay loop playsInline css={shopVideo} ref={videoRef}>
                    <source data-src={VideoSpWEBM} type="video/webm" />
                    <source data-src={VideoSp} type="video/mp4" />
                </video>
            </div>
            <div css={onlySpButton}>
                <OrderNowButton />
            </div>
        </div>
    )
}
export default FrontPageShopSection

const wrapper = css`
    display: flex;
    justify-content: space-between;
    padding-bottom: 30vh;
    @media ${device.sp} {
        display: block;
        padding-bottom: 15vmax;
    }
`
const leftContent = css`
    width: 40vw;
    @media ${device.sp} {
        width: 100%;
    }
`
const rightContent = css`
    width: 58vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2vw;
    padding-right: 2vw;

    @media ${device.sp} {
        display: block;
        width: 100%;
    }
`
const onlineShopImage = css`
    width: 100%;
    height: 70vmin;
    @media ${device.tb} {
        width: 95%;
        height: min(70vmin, 300px);
    }
    @media ${device.sp} {
        margin-bottom: 15vmin;
        width: 70%;
        height: 50vmax;
    }
`
const onlineShopImage2 = css`
    margin-top: 25vh;
    @media ${device.sp} {
        margin-top: 0;
        width: 70%;
        height: 50vmax;
        margin-left: 30%;
    }
`
const shopVideo = css`
    width: 100%;
    height: 70vmin;
    object-fit: cover;
    margin-top: -15vh;
    @media ${device.tb} {
        height: min(70vmin, 300px);
    }
    @media ${device.sp} {
        width: 70%;
        height: 50vmax;
        margin-top: 0;
        margin-left: 0;
    }
`
const textBox = css`
    width: 76%;
    margin: 15vh 12% 0;
    position: sticky;
    top: calc(10vh + 60px);

    @media ${device.tb} {
        margin: 5vh 12% 0;
    }

    @media ${device.sp} {
        width: 80%;
        margin: 0vmin auto 20vmin;
    }

    h2 {
        margin-bottom: 3rem;
        font-size: clamp(2.4rem, 2.4vw, 2.8rem);
    }
    p {
        margin-bottom: 5rem;
    }
`
const normalOrderButton = css`
    @media ${device.sp} {
        display: none;
    }
`
const onlySpButton = css`
    display: none;
    @media ${device.sp} {
        display: block;
        text-align: center;
        margin: 10vmax auto 0;
    }
`
