import * as React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"

const LearnMoreButton = (props) => {
    const intl = useIntl()

    return (
        <Link to={props.link} css={learnMore}>
            {intl.formatMessage({ id: "learn_more" })}
        </Link>
    )
}
export default LearnMoreButton

const learnMore = css`
    color: black;
    border-bottom: solid 1px black;
`
