import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/react"
import Video from "../images/hadawa_video.mp4"
import VideoWEBM from "../images/hadawa_video.webm"
import VideoSp from "../images/hadawa_video_sp.mp4"
import VideoSpWEBM from "../images/hadawa_video_sp.webm"
import { device } from "../styles/device"
import { useIntl } from "gatsby-plugin-react-intl"
import { motion } from "framer-motion"

const transition = { duration: 1.4, ease: [0.6, -0.05, 0.01, 0.9] }

const videoFadeIn = {
    initial: {
        opacity: 0,
        scale: 0.96,
    },
    animate: {
        opacity: 1,
        scale: 1,
    },
}

const HeroSection = () => {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            allContentfulFrontPage {
                nodes {
                    firstViewDescription {
                        firstViewDescription
                    }
                }
            }
        }
    `)

    const content = intl.locale === "ja" ? data.allContentfulFrontPage.nodes[0] : data.allContentfulFrontPage.nodes[1]

    return (
        <>
            <motion.div
                initial="initial"
                animate="animate"
                variants={videoFadeIn}
                transition={{ ...transition, delay: 0.6 }}
            >
                <video muted autoPlay loop playsInline css={video} id="first-view-video">
                    <source src={VideoSpWEBM} data-src={VideoWEBM} type="video/webm" />
                    <source src={VideoSp} data-src={Video} type="video/mp4" />
                </video>
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ...transition, delay: 1.5 }}>
                <p
                    css={firstViewDesc}
                    dangerouslySetInnerHTML={{
                        __html: content.firstViewDescription.firstViewDescription.replace(/\n/g, "<br>"),
                    }}
                />
                <p
                    css={firstViewDescSp}
                    dangerouslySetInnerHTML={{
                        __html: content.firstViewDescription.firstViewDescription.replace(/\n/g, ""),
                    }}
                />
            </motion.div>
        </>
    )
}
export default HeroSection

const video = css`
    width: 96vw;
    display: block;
    margin: 0 auto;
    object-fit: contain;
    height: 42.5vw;
    @media ${device.sp} {
        display: block;
        width: 96vw;
        margin: 0 auto;
        height: 170.67vw;
        object-fit: contain;
    }
`
const firstViewDesc = css`
    width: clamp(300px, 50%, 1000px);
    margin: 10vmin auto 0;
    padding-bottom: 15vmin;
    text-align: center;
    @media ${device.tb} {
        display: none;
    }
`
const firstViewDescSp = css`
    display: none;
    text-align: left;
    margin: 10vmin auto 0;
    padding-bottom: 15vmin;
    @media ${device.tb} {
        display: block;
        width: 80%;
    }
`
