import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import { device } from "../styles/device"
import LearnMoreButton from "./learnMoreButton"
import { useIntl } from "gatsby-plugin-react-intl"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Slider from "react-slick"

const FrontPageAboutUsSection = () => {
    const intl = useIntl()

    const controls = useAnimation()
    const { ref, inView } = useInView({
        triggerOnce: true,
    })

    useEffect(() => {
        inView ? controls.start("animate") : controls.start("initial")
    }, [controls, inView])

    const transition = { duration: 1.4, ease: [0.6, -0.05, 0.01, 0.9] }
    const textAnime = {
        initial: {
            y: 10,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
        },
    }

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        fade: true,
        pauseOnHover: false,
    }

    const data = useStaticQuery(graphql`
        query {
            allContentfulFrontPage {
                nodes {
                    aboutUsHeadline
                    aboutUsDescription {
                        aboutUsDescription
                    }
                    aboutUsImage {
                        gatsbyImageData
                    }
                }
            }
        }
    `)
    const content = intl.locale === "ja" ? data.allContentfulFrontPage.nodes[0] : data.allContentfulFrontPage.nodes[1]

    return (
        <div css={wrapper}>
            <motion.div
                css={textBox}
                ref={ref}
                initial="initial"
                animate={controls}
                transition={transition}
                variants={textAnime}
            >
                <h2>{content.aboutUsHeadline}</h2>
                <p
                    dangerouslySetInnerHTML={{
                        __html: content.aboutUsDescription.aboutUsDescription.replace(/\n/g, "<br>"),
                    }}
                />
                <LearnMoreButton link="/creators" />
            </motion.div>
            <Slider {...settings} css={slider}>
                {content.aboutUsImage.map((image) => (
                    <GatsbyImage
                        key={image.gatsbyImageData.images.fallback.src}
                        image={getImage(image)}
                        alt="about us image"
                        css={aboutUsImage}
                    />
                ))}
            </Slider>
        </div>
    )
}
export default FrontPageAboutUsSection

const wrapper = css`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-bottom: 35vh;
    @media ${device.tb} {
        padding-bottom: 30vmin;
    }
    @media ${device.sp} {
        display: block;
        padding-bottom: 15vmax;
    }
`
const textBox = css`
    width: 35%;
    padding: 0 5%;

    @media ${device.sp} {
        width: 80%;
        margin: 0 auto 8vmax;
        padding: 0;
    }

    h2,
    p {
        margin-bottom: 3rem;
    }
    h2 {
        font-size: clamp(2.4rem, 2.4vw, 2.8rem);
    }
`
const aboutUsImage = css`
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
`
const slider = css`
    position: relative;
    width: 55%;
    height: 60vh;
    max-height: 60vh;
    overflow: hidden;
    .slick-list,
    .slick-track {
        height: 100%;
    }

    @media ${device.sp} {
        width: 100%;
        height: 30vmax;
    }
`
