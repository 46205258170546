import * as React from "react"
import { Layout } from "../components/layout"
import HeroSection from "../components/heroSection"
import FrontPagesecondView from "../components/frontPageSecondView"
import FrontPageShopSection from "../components/frontPageShopSection"
import FrontPageAboutUsSection from "../components/frontPageAboutUsSection"
import PhotoGallerySection from "../components/photoGallerySection"

const IndexPage = () => {
    return (
        <Layout>
            <HeroSection />
            <FrontPagesecondView />
            <FrontPageShopSection />
            <FrontPageAboutUsSection />
            <PhotoGallerySection />
        </Layout>
    )
}
export default IndexPage
